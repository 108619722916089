// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
import 'cocoon';
//= require admin-lte/plugins/jQuery/jquery-2.2.3.min
//= require admin-lte/bootstrap/js/bootstrap.min
//= require admin-lte/dist/js/app.min
//= require admin-lte/plugins/iCheck/icheck

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//= require admin-lte/dist/js/adminlte.js
//= require moment/min/moment.min

const images = require.context('../images/', true);
import '../stylesheets/besthoiku.css';
